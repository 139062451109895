<template>

	<div>
		
		<b-card>
			<b-row>					
				<b-col cols="12" class="mb-1">
					<h5>FILTROS</h5>
				</b-col>
				
			    <b-col sm="12" md="4">
                    <label>Fecha</label>
                     <flat-pickr
                        v-model="filterStartDate"
                        class="form-control"
                        :config="config"
                    />
                </b-col>		
			</b-row>
		</b-card>
		
		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0">

			<div class="m-2">

				<!-- Table Top -->
				<b-row>

					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>
													   
					<!-- Search -->
					<b-col
						cols="12"
						md="4"
						class="ml-auto">
						<div class="d-flex ">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar contacto ..."
								trim
							/>							
						</div>
					</b-col>
				</b-row>

			</div>

			<b-table
				ref="refContactListTable"
				class="position-relative"
				:style="totalContacts < 3 && totalContacts > 0 ? 'height: 250px' : 'height: inherit'"
				:items="fetchContacts"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc">
								
				<template #cell(createdAt)="data">
					{{ formatDate(data.item.createdAt) }}
				</template>

				<template #cell(subject)="data">
					{{ data.item.subject.substring(0, 25) + "..." }}
				</template>

				<template #cell(message)="data">
					{{ data.item.message.substring(0, 30) + "..." }}
				</template>
				
				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL">

						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>
								
						<b-dropdown-item @click="viewContact(data.item._id)">
							<feather-icon icon="EyeIcon" />
							<span class="align-middle ml-50">Ver</span>
						</b-dropdown-item>

						<b-dropdown-item @click="deleteContact(data.item._id)">
							<feather-icon icon="TrashIcon" />
							<span class="align-middle ml-50">Eliminar</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>

			</b-table>
			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end">

						<b-pagination
							v-model="currentPage"
							:total-rows="totalContacts"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item">

							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
        
         <b-modal
            ref="modalContact"
            title="Formulario del Contacto"
            centered            
            ok-only
            ok-title="Cerrar"
            ok-variant="outline-primary"
        >
            <b-form @submit.prevent>
                <b-row>
                    <!-- first name -->
                    <b-col cols="12">
                        <b-form-group
                            label="Nombres"
                            label-for="vi-first-name"
                        >
                            <b-input-group class="input-group-merge">
                                <b-input-group-prepend is-text>
                                    <feather-icon icon="UserIcon" />
                                </b-input-group-prepend>
                                <b-form-input
                                    id="vi-first-name"
                                    v-model="names"
                                    disabled
                                    readonly
                                />
                            </b-input-group>
                        </b-form-group>
                    </b-col>

                    <!-- email -->
                    <b-col cols="12">
                        <b-form-group
                            label="Correo"
                            label-for="vi-email"
                        >
                            <b-input-group class="input-group-merge">
                                <b-input-group-prepend is-text>
                                    <feather-icon icon="MailIcon" />
                                </b-input-group-prepend>
                                <b-form-input
                                    id="vi-email"
                                    type="email"
                                    v-model="email"
                                    disabled
                                    readonly
                                />
                            </b-input-group>
                        </b-form-group>
                    </b-col>

                    <!-- mobile -->
                    <b-col cols="12">
                        <b-form-group
                            label="Teléfono"
                            label-for="vi-mobile"
                        >
                            <b-input-group class="input-group-merge">
                                <b-input-group-prepend is-text>
                                    <feather-icon icon="SmartphoneIcon" />
                                </b-input-group-prepend>
                                <b-form-input
                                    id="vi-mobile"
                                    type="text"
                                    v-model="phone"
                                    disabled
                                    readonly
                                />
                            </b-input-group>
                        </b-form-group>
                    </b-col>

					<!-- subject -->
                    <b-col cols="12">
                        <b-form-group
                            label="Asunto"
                            label-for="vi-rocket"
                        >
                            <b-input-group class="input-group-merge">
                                <b-input-group-prepend is-text>
                                    <feather-icon icon="PaperclipIcon" />
                                </b-input-group-prepend>
                                <b-form-input
                                    id="vi-rocket"
                                    type="text"
                                    v-model="subject"
                                    disabled
                                    readonly
                                />
                            </b-input-group>
                        </b-form-group>
                    </b-col>

                    <!-- message -->
                    <b-col cols="12">
                        <b-form-group
							class="mb-0"
                            label="Mensaje"
                            label-for="vi-message"
                        >
                             <b-form-textarea
                                id="vi-message"
                                v-model="message"
                                rows="4"
                                disabled
                                readonly
                            ></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </b-modal>
	</div>
</template>

<script>

	import {BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormDatepicker} from 'bootstrap-vue';
	import { useToast } from 'vue-toastification/composition';
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import store from '@/store';
    import vSelect from 'vue-select';
	import VueSweetalert2 from 'vue-sweetalert2';    
	import { Spanish } from 'flatpickr/dist/l10n/es';
    import flatPickr from 'vue-flatpickr-component';
	import { onUnmounted, reactive } from '@vue/composition-api';
	import useContactsList from './useContactsList';
	import contactStoreModule from '../contactStoreModule';
	import Vue from 'vue';    
	import 'animate.css';
	import axios from 'axios';
	import moment from 'moment';
	
	Vue.use(VueSweetalert2);

	export default {
		components: {
			BCard,
			BRow,
			BCol,
			BFormInput,
			BButton,
			BTable,
			BMedia,
			BAvatar,
			BLink,
			BBadge,
			BDropdown,
			BDropdownItem,
			BPagination,
			BFormDatepicker,	
            
            // 3rd party
            vSelect,
            flatPickr,
			moment
		},
		data() {                                   
			return {
				PublicUrl: process.env.VUE_APP_HOST,
                
                /* Campos de Formulario */
                names: null,
                email: null,
                phone: null,
                subject: null,
                message: null,   
                config: {
                    enableTime: false, 
                    dateFormat: 'd-m-Y',
                    locale: Spanish
                },             
			}
		}, 
		methods: {
            viewContact(id) {
              	store.dispatch('app-contact/fetchContact', { id })
                .then( response => {
                    if(response.data){
                        this.names = response.data.names
                        this.email = response.data.email
                        this.phone = response.data.phone
                        this.subject = response.data.subject
                        this.message = response.data.message
                        
                        this.$refs['modalContact'].show()
                    }
                })
            }
		},
		setup() {

			// Use toast
			const toast = useToast();

			const USER_APP_STORE_MODULE_NAME = 'app-contact';

			// Register module
			if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, contactStoreModule);

			// UnRegister on leave
			onUnmounted(() => {
				if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
			});

			const formatDate = (date) => {
				return moment(date).format('DD/MM/YYYY HH:mm:ss');
			}
												
			const optionsCategories = reactive([]);
			
			axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/categories`)
			.then(response => {
				if(response){
					response.data.map( item => {
						optionsCategories.push({
							label : item.name, 
							value : item._id
						});
					});
				}
			});
					
			const deleteContact = (id) => {
		
					Vue.swal({
						title: '¿Estás seguro de eliminar el mensaje?',
						text: "¡Si no lo está, puede cancelar la acción!",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: 'Sí, eliminar!',
						cancelButtonText: 'Cancelar',
						customClass: {
							confirmButton: 'btn btn-primary',
							cancelButton: 'btn btn-outline-danger ml-1'
						},
						showClass: {
							popup: 'animate__animated animate__tada'
						},
						buttonsStyling: false
					}).then(result => {
						if (result.value) {
							store.dispatch('app-contact/deleteContact', { id })
								.then( (response) => {
									refetchData();
									toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'CheckIcon',
											variant: 'success'
										}
									});
								})
								.catch( () => {
									toast({
										component: ToastificationContent,
										props: {
											title: 'Error al eliminar el contacto',
											icon: 'AlertTriangleIcon',
											variant: 'danger'
										}
									});
								});
						}
					});
			};
			
			const {
				fetchContacts,
				tableColumns,
				perPage,
				currentPage,
				totalContacts,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refContactListTable,
				refetchData,
				
				filterStartDate,
				
			} = useContactsList();
			
			return {
				fetchContacts,
				tableColumns,
				perPage,
				currentPage,
				totalContacts,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refContactListTable,
				refetchData,
				
				// Filter
				deleteContact,
				filterStartDate,

				formatDate
			}
		}
	}

</script>

<style lang="scss" scoped>

	.media {
		align-items: center;
	}

	.per-page-selector {
		width: 90px;
	}    

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
	@import '@core/scss/vue/libs/vue-sweetalert.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>