import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import moment from 'moment/src/moment';

export default function useContactsList() {

    // Use toast
    const toast = useToast();

    const refContactListTable = ref(null);

    // Table Handlers
    let tableColumns = [
        { key: 'names', sortable: true, label: 'Nombres' },
        { key: 'email', sortable: true, label: 'Correo' },
        { key: 'phone', sortable: true, label: 'Teléfono' },
        { key: 'subject', sortable: false, label: 'Asunto' },
        { key: 'message', sortable: true, label: 'Mensaje' },
        { key: 'createdAt', sortable: true, label: 'Fecha' },
        { key: 'actions', label: 'Opciones' }
    ];

    const perPage = ref(10);
    const totalContacts = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('createdAt');
    const isSortDirDesc = ref(true);
    const filterStartDate = ref(null);
    const filterSD = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refContactListTable.value ? refContactListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalContacts.value,
        }
    });

    const refetchData = () => {
        refContactListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, filterStartDate], () => {
        filterSD.value = moment(filterStartDate.value, "DD-MM-YYYY").toISOString();
        refetchData();
    });

    const fetchContacts = (ctx, callback) => {

        store
            .dispatch('app-contact/fetchContacts', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                filterStartDate: filterSD.value ? filterSD.value.split('T')[0] : null,
            })
            .then(response => {
                const { contacts, total } = response.data;
                callback(contacts);
                totalContacts.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de contactos',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchContacts,
        tableColumns,
        perPage,
        currentPage,
        totalContacts,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refContactListTable,

        refetchData,
        // Extra Filters
        filterStartDate,
    }
}